.container {
  background-color: #111;
  min-height: 300px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container.none {
}

.container.low {
}

.container.medium {
}

.container.high {
}

.container.parallax {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.title {
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  text-shadow: 0px 0px 10px #111;
  text-align: center;
}
